import React, { useEffect, useState } from 'react';
import loadable from '@loadable/component';
import { navigate } from 'gatsby';

import { Box, CircularProgress, Typography, Button } from '@mui/material';

import { Layout, Flex } from '@components/layout';
import { useAuth, useUser } from '@context';

const Escala = loadable(() => import('@screens/escala/Escala'));
const EscalaMedico = loadable(() => import('@screens/escala_medico/EscalaMedico'));

const SimpleButton = ({ ...props }) => <Button variant="contained" color="primary" {...props} />;

function Index(props) {
  const auth = useAuth();
  const userProps = useUser();

  const [userInfo, setUserInfo] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (auth.user) {
      getInfo();
    }
  }, [auth]);

  const getInfo = async () => {
    const result = await userProps?.getUserInfo(auth?.user.uid);

    setUserInfo(result);
    setLoading(false);
  };

  const getComponent = () => {
    if (!auth?.user?.uid) return <div />;
    const infoProps = {
      userId: auth.user.uid,
      userInfo,
    };

    if (userInfo.selected === 1) return <EscalaMedico key="escala medico" {...props} {...infoProps} />;

    if (userInfo.selected === 2) {
      return (
        <Flex key="select escala" sx={{ flexDirection: 'column' }}>
          <Typography variant="h5">Selecione a escala que quer abrir:</Typography>
          <Box sx={{ display: 'flex', gap: '1rem', mt: '2rem' }}>
            <SimpleButton onClick={() => navigate(`/escala/gestor`)}>Escala do gestor</SimpleButton>
            <SimpleButton onClick={() => navigate(`/escala/medico`)}>Escala do médico</SimpleButton>
          </Box>
        </Flex>
      );
    }

    return <Escala key="escala gestor" {...props} {...infoProps} />;
  };

  if (loading) {
    return (
      <Layout key={0}>
        <Flex>
          <CircularProgress />
        </Flex>
      </Layout>
    );
  }

  return (
    <Layout key={1} {...props}>
      {getComponent()}
    </Layout>
  );
}

export default Index;
